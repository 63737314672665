import React, { useEffect, useState } from "react";
import { Form, Input, Label } from "@lipitech/form";
import { FormSchema } from "./tenant.schema";
import { useNavigate, useParams } from "react-router-dom";
import {
  createNewTenant,
  getTenantById,
  updateTenant,
} from "src/config/api/tenant";
import Swal from "sweetalert2";
import { uploadFile } from "src/config/api/assets";
import { GrantResources } from "src/enums/permission";
import { CheckBox } from "src/components/common/checkbox/checkbox";
import CustomSelect from "src/components/common/custom-select";
import FileUpload from "src/components/common/FileUpload";
import { TagInputField } from "src/components/common/input/tag-input";
// import { Loader } from "@lipitech/ec-ui";

const Detail = () => {
  const navigate = useNavigate();

  const { institutionId } = useParams();

  const [tenantDetails, setTenantDetails] = useState<any>({});
  const [permissions, setPermissions] = useState<any>([]);
  // const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getTenantDetails = async () => {
      const { data }: any = await getTenantById(institutionId);
      if (data?.eduDomains?.admin && data?.eduDomains?.student) {
        data.eduDomains = [data?.eduDomains?.admin, data?.eduDomains?.student];
      } else data.eduDomains = [];
      setTenantDetails(data);
      setPermissions(data?.modules || []);
      // setLoading(false);
    };
    if (institutionId) getTenantDetails();
  }, [institutionId]);

  const handleSubmit = async (values: any) => {
    // setLoading(true);
    console.log(values);
    try {
      values.modules = permissions;
      if (institutionId) {
        delete values.tenantId;
        delete values.active;
        delete values.secret;
        delete values.updatedAt;
        delete values.eduDomains;
        delete values.auth;
        delete values.bucket;
        delete values.bucketUrl;
        delete values.createdAt;
        delete values.user;
        delete values.socials;
        delete values.styles;
        delete values.smtp;
        delete values.payment;
        delete values.sms;
        delete values.title;
        delete values.show;
      }
      const data = institutionId
        ? await updateTenant(tenantDetails?._id, values)
        : await createNewTenant(values);
      if (data) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: institutionId ? "Institution updated!" : "Institution created!",
        }).then(() => {
          navigate("/listing");
        });
      }
    } catch {
      console.log("error");
    }
  };

  const handleChange = (value: any, event: any) => {
    let selectedPermissions = [...permissions];
    if (event.target.checked) {
      selectedPermissions.push(value);
    } else {
      selectedPermissions = selectedPermissions.filter(
        (v: string) => v !== value
      );
    }
    console.log(selectedPermissions);

    setPermissions(selectedPermissions);
  };
  return (
    <>
      <div className="page-header">
        <div className="d-flex">
          <div className="back">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              color="#00a689"
              width="1em"
              height="1em"
              style={{ color: "rgb(0, 166, 137)" }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M401.4 224h-214l83-79.4c11.9-12.5 11.9-32.7 0-45.2s-31.2-12.5-43.2 0L89 233.4c-6 5.8-9 13.7-9 22.4v.4c0 8.7 3 16.6 9 22.4l138.1 134c12 12.5 31.3 12.5 43.2 0 11.9-12.5 11.9-32.7 0-45.2l-83-79.4h214c16.9 0 30.6-14.3 30.6-32 .1-18-13.6-32-30.5-32z"></path>
            </svg>
          </div>
          <div className="wrap">
            <h1>Add an Institution</h1>
            <p>
              Start with the selection of type of institution and fill the form
              below
            </p>
          </div>
        </div>
      </div>
      <Form
        initialValues={tenantDetails}
        validationSchema={FormSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        <div className="row">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <Input
                    id="institutionType"
                    name="institutionType"
                    label="Select Type Of Institution"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <Input
                    id="name"
                    name="name"
                    label="Name of the institution"
                    required
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <Input
                    id="centerCode"
                    name="centerCode"
                    label="Center Code"
                    required
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <Input
                    id="gstName"
                    name="gstName"
                    label="GST Name"
                    required
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <Input
                    id="gstNumber"
                    name="gstNumber"
                    label="GST Number"
                    required
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <Input
                    id="contactPersonName"
                    name="contactPersonName"
                    label="Contact Person name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <Input id="address" name="address" label="Address" />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <Input
                    id="email"
                    name="email"
                    label="Primary Email ID"
                    required
                    type="email"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <Input
                    id="mobile"
                    name="mobile"
                    label="Primary Mobile Number"
                    required
                    type="mobile"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <Input id="website" name="website" label="Website" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <CustomSelect
                    id="eduDomains"
                    name="eduDomains"
                    label="Edu domains"
                    values={tenantDetails?.eduDomains}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <TagInputField
                  name="domains.admin"
                  id="domains.admin"
                  label="Custom Admin Domains"
                />
              </div>
              <div className="col-md-6">
                <TagInputField
                  name="domains.student"
                  id="domains.student"
                  label="Custom Students Domains"
                />
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Input
                    id="domains.proctored"
                    name="domains.proctored"
                    label="proctored test URL"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Input
                    id="bankAccountId"
                    name="bankAccountId"
                    label="Bank Account Id"
                  />
                </div>
              </div>
            </div>
            <div className="upload-wrapper mt-3">
              <h3>Images & Documents</h3>

              <div className="upload-wrapper-row">
                <p>Logo</p>
                <div className="custom-upload flex-grow-1">
                  {/* @ts-ignore */}
                  <FileUpload
                    // label="logo"
                    name="logo"
                    required
                    accept={["image/jpeg", "image/jpg", "image/png"]}
                    // assetUrl={EducrackAPI.asset.getAssetUrl}
                    upload={uploadFile}
                  // deleteFile={deleteFile}
                  // getUploadFile={event =>
                  //   onUploadFileHandler(event, values)
                  // }
                  // getDeleteFile={() => onDeleteFileHandler(values)}
                  />
                </div>

                <span style={{ minWidth: 208 }}>JPG, JPEG, PNG only.</span>
              </div>

              <div className="upload-wrapper-row">
                <p>PAN Card</p>
                <div className="custom-upload flex-grow-1">
                  <FileUpload
                    name="pan"
                    label=""
                    // previewTarget={tenantDetails.pan}
                    accept={[
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                      "application/pdf",
                    ]}
                    upload={(e) => uploadFile(e)}
                    type="single"
                    maxSize={"1mb"}
                    // isCancelAllowed
                    required
                  />
                </div>

                <span style={{ minWidth: 208 }}>
                  PDF, JPG, JPEG, PNG only, Size not exceeding 1MB
                </span>
              </div>

              <div className="upload-wrapper-row">
                <p>Aadhar Card</p>
                <div className="custom-upload flex-grow-1">
                  <FileUpload
                    name="aadhar"
                    accept={[
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                      "application/pdf",
                    ]}
                    // @ts-ignore
                    uploadFile={uploadFile}
                    type="single"
                    maxSize={"1mb"}
                    isCancelAllowed
                    required
                  />
                </div>
                <span style={{ minWidth: 208 }}>
                  PDF, JPG, JPEG, PNG only, Size not exceeding 1MB
                </span>
              </div>

              <div className="upload-wrapper-row">
                <p>Registration Certificate</p>
                <div className="custom-upload flex-grow-1">
                  <FileUpload
                    name="registrationCertificate"
                    accept={[
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                      "application/pdf",
                    ]}
                    // @ts-ignore
                    uploadFile={uploadFile}
                    type="single"
                    maxSize={"1mb"}
                    isCancelAllowed
                    required
                  />
                </div>
                <span style={{ minWidth: 208 }}>
                  PDF, JPG, JPEG, PNG only, Size not exceeding 1MB
                </span>
              </div>
            </div>

            <br />
            <br />
            <h5 className="text-secondary mt-4">
              ALLOW PERMISSIONS FOR MODULES:
            </h5>
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  {Object.values(GrantResources).map((value) => {
                    return (
                      <div
                        key={value}
                        className="text-secondary list-group-item"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px 40px",
                          fontSize: "14px",
                        }}
                      >
                        <Label label={value} takeSpace="5px" />
                        <div className="d-flex align-items-center">
                          <CheckBox
                            // id={value}
                            selectedPermissions={permissions}
                            name={value}
                            value={value}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mt-3">
              <button
                className="btn btn-secondary me-2"
                onClick={() => navigate("/listing")}
              >
                Back to listing page
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default Detail;
