import React, { KeyboardEventHandler, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { Label } from "@lipitech/form";

import { useField } from "formik";
import { ErrorMessage } from "../error/error";

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

export const createOption = (label: string) => ({
  label,
  value: label,
});

const CustomSelect = ({
  id,
  label,
  name,
  isMulti = true,
  required,
  values,
  disabled,
  placeholder = "",
}: any) => {
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState<readonly Option[]>([]);
  const [field, meta, helpers] = useField({ name });

  useEffect(() => {
    if (values?.length > 0) {
      values = values?.map((str: string) => ({
        value: str,
        label: str,
      }));
      setValue(values);
    }
  }, [values]);

  const onChange = (value: any) => {
    if (isMulti) {
      value && helpers.setValue(value.map((item: any) => item.value));
    } else {
      value && helpers.setValue(value.value);
    }
    setValue(value);
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        const oldValues = [...(value || []), createOption(inputValue)];
        setValue(oldValues);
        setInputValue("");
        event.preventDefault();
        onChange(oldValues);
    }
  };

  return (
    <>
      <Label label={label} id={id} required={required} />
      <CreatableSelect
        {...field}
        id={id}
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={(newValue: any) => onChange(newValue)}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={value}
        isDisabled={disabled}
      />
      <ErrorMessage meta={meta} />
    </>
  );
};

export default CustomSelect;
